<template>
  <div>
    <CCard class="mt-3" @keypress.enter="search">
      <CCardHeader> <TMessage content="Tracking images" bold /> </CCardHeader>
      <CCardBody class="py-2">
        <CRow>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputText
              label="Tracking Id"
              placeholder="Tracking Id"
              :value.sync="filter['sfa.tracking_code']"
              :inputOptions="{ lazy: true }"
              @input="filter['sfa.tracking_code'] = $event"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TInputDateTimeRange
              label="Created at"
              :value.sync="createdRange"
              @update:value="setCreatedAtFilter"
            />
          </CCol>
          <CCol col="12" sm="6" md="3" class="mb-3">
            <TMessage content="Tracking is not distributed" class="mb-2" bold />
            <TSwitch
              :checked="sfa_distributed"
              @change="sfa_distributed = $event"
              label="Tracking is not distributed"
              class="mb-1"
            />
          </CCol>
        </CRow>
        <div class="float-right mt-3">
          <SButtonClearFilter @click="clearFilter" />
          <SButtonSearchFilter
            class="ml-2"
            :disabled="loading"
            @click="search"
          />
        </div>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol
        col="12"
        lg="3"
        md="4"
        v-for="receipt in receipts"
        :key="receipt.id"
      >
        <TImg
          class="p-0 mt-2"
          :src="getUrl(receipt.path_file)"
          @click.native="selectReceipt(receipt)"
          clickable
        >
          <template #title>
            <TMessage
              :content="receipt.sfa ? receipt.sfa.tracking_code : '...'"
              bold
              noTranslate
            />
            <TMessageDateTime :content="receipt.created_at" dateOnly />
          </template>
        </TImg>
      </CCol>
    </CRow>
    <CModal
      class="modal-custom p-0"
      :show="show"
      size="xl"
      centered
      :fade="false"
      @update:show="show = $event"
    >
      <template #header>
        <TMessage
          :content="
            receiptSelected.sfa ? receiptSelected.sfa.tracking_code : '...'
          "
          noTranslate
          bold
        />
        <TButton
          content="View Sfa"
          color="info"
          :to="lodash.getReferenceLink('sfa', receiptSelected.sfa_id)"
          variant="outline"
          size="sm"
          class="ml-auto"
          icon="cil-arrow-right"
        />
        <TButton
          color="primary"
          size="sm"
          variant=""
          @click="download"
          icon="cid-cloud-download"
          class="ml-1"
          content="Download"
        />
        <TButton
          color="dark"
          size="sm"
          variant="outline"
          class="ml-1"
          @click="show = false"
          tooltip="Close"
          icon="cis-x"
        />
      </template>
      <CImg
        style="object-fit: contain; min-height: 300px"
        thumbnail
        fluidGrow
        :src="src"
        placeholderColor
        class="p-0 d-flex mx-auto"
      />
      <TButton
        v-if="receiptIndex > 0"
        class="btn-left btn-arrow shadow"
        color="info"
        icon="cilArrowLeft"
        @click="changeUrl('pre')"
      />
      <TButton
        v-if="receiptIndex + 1 < receipts.length"
        class="btn-right btn-arrow shadow"
        color="info"
        icon="cilArrowRight"
        @click="changeUrl('next')"
      />
    </CModal>
    <TMessageNoData v-if="!receipts.length" />
    <TPagination store="warehouse.tracking_receipts" class="mt-4" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {
        "sfa.tracking_code": "",
      },
      filter_between: {},
      createdRange: {
        start: null,
        end: null,
      },
      sfa_distributed: false,
      show: false,
      src: "",
      receiptSelected: {},
      receiptIndex: 0,
    };
  },
  created() {
    this.$store.dispatch("warehouse.tracking_receipts.fetch");
  },
  mounted() {
    window.addEventListener("keydown", this.keyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyPress);
  },
  computed: {
    ...mapGetters({
      receipts: "warehouse.tracking_receipts.list",
      loading: "warehouse.tracking_receipts.loading",
    }),
  },
  methods: {
    getUrl(path) {
      return process.env.VUE_APP_ORDER_SERVICE_HOST + "/files/" + path;
    },
    search() {
      if (this.loading) return;
      this.$router.push({}).catch(() => {});
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...filterBetween,
      });
      const distributedFilter = this.sfa_distributed && {
        "filter[sfa.distributed]": false,
      };
      this.$store.dispatch(`warehouse.tracking_receipts.apply-query`, {
        ...distributedFilter,
        ...query,
      });
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
    },
    clearFilter() {
      this.createdRange = {
        start: null,
        end: null,
      };
      this.filter_between = {};
      this.filter = {
        "sfa.tracking_code": "",
      };
      this.sfa_distributed = false;
    },
    selectReceipt(rc) {
      this.src = this.getUrl(rc.path_file);
      this.receiptSelected = rc;
      this.show = !this.show;
      this.receiptIndex = this.receipts.findIndex((x) => x.id == rc.id);
    },
    download() {
      window.open(this.src);
    },
    changeUrl(status) {
      let par = this.receiptIndex;
      if (status == "pre" && par > 0) par = par - 1;
      if (status == "next" && par < 30) par = par + 1;
      if (par == this.receiptIndex) return;

      this.receiptSelected = this.receipts[par];
      this.src = this.getUrl(this.receiptSelected?.path_file);
      this.receiptIndex = par;
    },
    keyPress(e) {
      if (e.keyCode == 37) this.changeUrl("pre");
      if (e.keyCode == 39) this.changeUrl("next");
    },
  },
};
</script>

<style scoped>
.modal-body .btn-left {
  position: fixed;
  left: 10%;
  bottom: 50%;
}
.modal .btn-arrow {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  opacity: 1;
}
.modal-body .btn-right {
  position: fixed;
  right: 10%;
  bottom: 50%;
}
* >>> .modal-custom .modal-footer {
  display: none;
}
</style>
